import React, { useEffect, useRef } from 'react';

import { IModalProps } from './Types';

import './Modal.scss';

const Modal: React.FC<IModalProps> = ({ close, children }): JSX.Element => {
  const ref = useRef<HTMLDivElement>();
  const handleClickOutside = (e: any) => {
    if (ref && !ref.current.contains(e.target)) {
      close();
    }
    document.body.classList.remove('fixed');
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <div className="modal-wrapper">
      <div className="modal-window-container" ref={ref}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
